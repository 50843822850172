<!--
 * @Auth: linjituan
 * @Date: 2021-08-26 11:52:50
 * @LastEditors: linjituan
 * @LastEditTime: 2021-08-26 19:32:47
-->
<template>
  <page-header-wrapper>
    <a-card>
      <expense-form :ref="type" v-if="type === 'expense'" source="menu" @ok="getList" />
      <rechargeForm :ref="type" v-if="type === 'recharge'" source="menu" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import expenseForm from '@/views/iot/expenses/modules/CreateForm.vue'
import rechargeForm from '@/views/iot/oilAccountRecord/modules/rechargeForm.vue'
export default {
  name: 'MenuPage',
  components: {
      expenseForm,
      rechargeForm
  },
  data() {
    return {
      type: ''
    }
  },
  created() {
    document.title = this.$route.query.name
    this.$route.meta.title = this.$route.query.name
    this.type = this.$route.query.type
  },
  mounted() {
    // this.$refs[this.type].handleAdd()
  },
  methods: {}
}
</script>

<style></style>
