var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        [
          _vm.type === "expense"
            ? _c("expense-form", {
                ref: _vm.type,
                attrs: { source: "menu" },
                on: { ok: _vm.getList },
              })
            : _vm._e(),
          _vm.type === "recharge"
            ? _c("rechargeForm", { ref: _vm.type, attrs: { source: "menu" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }